<template>
  <div :class="classes">
    <button
      type="button"
      :class="plain ? '' : (buttonClasses ?? defaultThemedProp_ButtonClasses)"
      :disabled="disabled"
      @click="emit('click')"
    >
      <FaIcon
        v-if="props.icon"
        :icon-class="props.icon"
        :classes="props.iconClasses"
        :testid="testid"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  icon: {
    type: String,
    required: true,
    default: () => {
      return null;
    },
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  buttonClasses: {
    type: String,
    required: false,
    default: null,
  },
  iconClasses: {
    type: String,
    required: false,
    default: '',
  },
  plain: {
    type: Boolean,
    required: false,
    default: false,
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

const defaultThemedProp_ButtonClasses =
  useSiteIdent() === SiteIdent.default
    ? 'text-white bg-vdv-base custom-button-icon'
    : 'text-white bg-minilu-base custom-button-icon rounded-minilu-md';

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>
